<template>
  <div>
    <b-modal
      ref="myModal"
      v-model="modalCenter"
      centered
      size="lg"
      title="CLIENTS LIST"
      title-tag="h3"
      modal-class="modal-primary"
      no-close-on-backdrop
      @hidden="hideModal"
    >
      <!-- HEADER START -->
      <template v-slot:modal-header>
        <div class="w-100">
          <b-button-close
            style="transform: translate(-2px, 2px)"
            @click="hideModal"
          />
          <b-container fluid>
            <b-row class="text-center bg-primary">
              <h3 class="font-weight-bolder text-white">
                {{ type == 2 ? "APPROVE" : "DISSAPROVE" }}
              </h3>
            </b-row>
          </b-container>
        </div>
      </template>
      <b-container>
        <b-row>
          <b-col>
            <h5>CLIENT</h5>
            <p
              class="rounded text-primary border-primary font-medium-1 text-center p-1"
              style="width: 50%"
            >
              {{ dataClient.name_client }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2" class="ml-1 bg-primary rounded-top paddingTop">
            <h6 class="text-white text-center">COMMENTARY</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-observer ref="refFormObserver">
              <validation-provider
                name="Commentary"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="commentary"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                  :state="errors[0] ? false : null"
                ></b-form-textarea>
              </validation-provider>
            </validation-observer>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button @click="hideModal">Cancel</b-button>
        <b-button variant="primary" @click="onSubmit">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AccountsActionsService from "../service/actions.service";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import { ValidationObserver } from "vee-validate";
export default {
  components: {
    ButtonSave,
    ButtonCancel,
    ValidationObserver,
  },
  props: {
    dataClient: {
      type: Object,
    },
    type: {
      type: Number,
    },
  },
  data() {
    return {
      modalCenter: true,
      commentary: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    getAccounts(){
      if(this.dataClient.account_creditor){
        if(this.dataClient.account_creditor.length >=4){
          return "#. "+this.dataClient.account_creditor.substring(this.dataClient.account_creditor.length - 4);
        } else {
          return "#. "+this.dataClient.account_creditor;
        }
      }else{
        return "Undefined";
      }
      
    },
  },
  methods: {
    async onSubmit() {
      if (await this.$refs.refFormObserver.validate()) {
        let confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          this.addPreloader();
          try {
            await AccountsActionsService.updateAccountActionId({
              id_user: this.currentUser.user_id,
              id_account_action: this.dataClient.id,
              statuss: this.type,
              comment: this.commentary,
              id_account: this.dataClient.id_account,
              entidadName: this.dataClient.name_bank,
              charge_amg: this.dataClient.charge_amg,
              account: this.getAccounts,
            });
            this.$emit("updateTable");
            this.hideModal();
            this.removePreloader();
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "The letters has been update."
            );
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    hideModal() {
      this.$refs['myModal'].hide();
      this.modalCenter = false;
      this.$emit("hideModal");
    },
  },
  mounted() {},
};
</script>
<style scoped>
.paddingTop {
  padding-top: 12px;
}
</style>
