export default [
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Advisor",
    model: null,
    options: [],
    reduce: "id",
    selectText: "user_name",
    cols: 12,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status",
    model: null,
    options: [
      { id: "0", label: "All" },
      { id: "1", label: "PENDING" },
      { id: "2", label: "APPROVED" },
      { id: "3", label: "DISAPPROVED" },
    ],
    reduce: "id",
    selectText: "label",
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Result",
    model: null,
    options: [
      { id: "0", label: "All" },
      { id: "6", label: "Removed (R)" },
      { id: "9", label: "No Balance (Nb)" },
    ],
    reduce: "id",
    selectText: "label",
    cols: 6,
  },
];
