<template>
  <div>
    <b-card no-body class="mb-0 border-0 p-0">
      <filter-slot
        :filter="isAdvisor"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refUserListTable'].refresh()"
      >
        <b-table
          slot="table"
          ref="refUserListTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          show-empty
          sort-icon-left
          :busy="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :fields="fields"
          :items="myProvider"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- Column: CLIENTS Redirect Debt Solution Clients -->
          <template #cell(name_client)="data">
            <div>
              <p class="m-0">
                <router-link
                  target="_blank"
                  class
                  :to="`/debtsolution/clients/account/${data.item.id_account}`"
                >
                  {{ data.item.name_client }}</router-link
                >
              </p>

              <p class="m-0">{{ data.item.account }}</p>
            </div>
          </template>
          <!-- Column: Account -->
          <template #cell(name_bank)="data">
            <div>
              <p class="mb-0">{{ data.item.name_bank }}</p>
              <p>#: {{ data.item.account_creditor }}</p>
            </div>
          </template>
          <!-- Column: Blance -->
          <template #cell(balance)="data">
            <div class="pt-1">
              <p>$ {{ data.item.balance }}</p>
            </div>
          </template>
          <!-- Column: OFFER AMOUNT -->
          <template #cell(offer_amount)="data">
            <div class="pt-1">
              <p>$ {{ data.item.offer_amount }}</p>
            </div>
          </template>
          <!-- Column: Settlement Fee -->
          <template #cell(settlement_fee)="data">
            <div class="pt-1">
              <p>$ {{ data.item.settlement_fee }}</p>
            </div>
          </template>
          <template #cell(created_at)="data">
            <div>
              <p class="mb-0">{{ data.item.name_created_by }}</p>
              <p>{{ data.item.created_at | myGlobalDay }}</p>
            </div>
          </template>
          <!-- Column: Tracking -->
          <template #cell(tracking)="data">
            <div class="text-center">
              <b-button variant="outline" @click="openModalTracking(data.item)">
                <feather-icon
                  class="text-primary"
                  v-b-tooltip.hover.right="'Tracking'"
                  icon="ListIcon"
                />
              </b-button>
            </div>
          </template>
          <!-- Column: STATUS -->
          <template #cell(status)="data">
            <div>
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                :class="
                  data.item.status === 1
                    ? 'text-capitalize w-100'
                    : 'text-capitalize w-100 mt-1'
                "
              >
                <p v-if="data.item.status === 1" class="mb-0">PENDING</p>
                <p v-if="data.item.status === 2" class="mb-0">APPROVED</p>
                <p v-if="data.item.status === 3" class="mb-0">DISSAPROVED</p>
              </b-badge>
              <div
                v-if="
                  data.item.status === 1 && (isCeo || isSupervisor || isChief)
                "
                class="d-flex justify-content-center"
                style="margin-top: 8px"
              >
                <b-button
                  variant="primary"
                  pill
                  size="sm"
                  @click="modalStatus(data.item, 2)"
                  >Approve</b-button
                >
                <b-button
                  variant="danger"
                  pill
                  size="sm"
                  class="ml-1"
                  @click="modalStatus(data.item, 3)"
                  >Dissaprove</b-button
                >
              </div>
            </div>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
    <modal-status
      v-if="OpenModalStatus"
      :dataClient="dataClient"
      :type="type"
      @hideModal="OpenModalStatus = false"
      @updateTable="$refs['refUserListTable'].refresh()"
    />
    <modal-tracking
      v-if="modalTrackingState"
      :dataClient="dataClient"
      @hideModal="modalTrackingState = false"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DataFields from "./fields.data";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import DataFilters from "./filters.data";
import AccountsActionsService from "../../service/actions.service";
import ModalStatus from "../ModalStatus.vue";
import ModalTracking from "../ModalTracking.vue";
export default {
  components: {
    FilterSlot,
    ModalStatus,
    ModalTracking,
  },
  data() {
    return {
      fields: DataFields.AccountsActionsFields,
      filter: DataFilters,
      sortBy: "created_at",
      sortDesc: true,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      items: [],
      modalTrackingState: false,
      OpenModalStatus: false,
      dataClient: [],
      all: { id: 0, label: "All" },
      advisor: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isAdvisor() {
      let result = [];
      if (this.currentUser.role_id <= 2 || this.currentUser.role_id == 4) {
        //CEO, Supervisor and Operator
        result = this.filter;
        return result;
      } else if (this.currentUser.role_id == 3) {
        // Advisor
        result = [...this.filter];
        result.splice(0, 1);
        return result;
      }
    },
  },
  methods: {
    modalStatus(data, type) {
      this.OpenModalStatus = true;
      this.dataClient = data;
      this.type = type;
    },
    async getAdvisor() {
      let result = await AccountsActionsService.getUsersAllPrograms({
        id: this.currentUser.modul_id,
      });
      if (result) {
        result.unshift({
          id: 0,
          user_name: "All",
        });
        result.push({
          id: 1,
          user_name: "Roger Segura",
        });
      }
      this.filter[0].options = result;
    },
    openModalTracking(data) {
      this.modalTrackingState = true;
      this.dataClient = data;
    },
    async myProvider(ctx) {
      try {
        let result = await AccountsActionsService.getAccountsActions({
          name_text: this.filterPrincipal.model,
          id_advisor: this.filter[0].model ? this.filter[0].model : 0,
          order: ctx.sortDesc == 1 ? "desc" : "asc",
          orderby: 3,
          rresult: this.filter[2].model ? this.filter[2].model : 0,
          sstatus: this.filter[1].model ? this.filter[1].model : 0,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
        });

        this.startPage = result.from;
        this.toPage = result.to;
        this.totalRows = result.total;
        this.paginate.currentPage = result.current_page;
        this.paginate.perPage = result.per_page;
        return result.data;
      } catch (error) {
        console.log("Somtehing went wrong myProvider", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    resolveUserStatusVariant(status) {
      if (status === 1) return "warning";
      if (status === 2) return "success";
      if (status === 3) return "danger";
      return "primary";
    },
  },
  async created() {
    await this.getAdvisor();
  },
};
</script>
<style>

</style>
