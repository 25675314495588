<template>
  <div>
    <b-modal
      ref="myModal"
      v-model="modalCenter"
      centered
      modal
      size="lg"
      header-class="p-0"
      no-close-on-backdrop
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title="TRACKING"
      title-class="h2 text-white"
      hide-footer
      @hidden="hideModal"
    >
      <!-- HEADER START -->
      <template v-slot:modal-header>
        <ModalTrackingsHeader
          :dataClient="dataClient"
          @close="hideModal(false)"
        />
      </template>
      <b-container>
        <b-table
          slot="table"
          ref="refUserListTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          show-empty
          :fields="fields"
          :items="myProvider"
        >
          <!-- Loading -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- Column: ACTION BY -->
          <template #cell(action_by)="data">
            <div class="text-left">
              <p class="mb-0">{{ data.item.name_created_by }}</p>
              <p class="mb-0">{{ data.item.created_at | myGlobalWithHour }}</p>
            </div>
          </template>
          <!-- Column: STATUS -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize w-100 p-0 mt-1"
            >
              <p v-if="data.item.status === 1" class="m-0">PENDING</p>
              <p v-if="data.item.status === 2" class="m-0">APPROVED</p>
              <p v-if="data.item.status === 3" class="m-0">
                DISSAPROVED
              </p></b-badge
            >
            <div class="text-left"></div>
          </template>
          <!-- Column: OBSERVATION -->
          <template #cell(observation)="data">
            <div class="text-left">
              <div class="observation" v-html="data.item.commentary"></div>
            </div>
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import ModalTrackingsHeader from "./ModalTrackingHeader.vue";
import AccountsActionsService from "../service/actions.service";
export default {
  components: {
    ModalTrackingsHeader,
  },
  props: {
    dataClient: {
      type: Object,
    },
  },
  data() {
    return {
      modalCenter: true,
      fields: [
        {
          key: "action_by",
          label: "Action By",
        },
        {
          key: "status",
          thClass: "text-center",
        },
        {
          key: "observation",
          thClass: "text-center",
        },
      ],
    };
  },
  methods: {
    hideModal() {
      this.$refs['myModal'].hide();
      this.modalCenter = false;
      this.$emit("hideModal");
    },
    async myProvider() {
      this.addPreloader();
      try {
        let result = await AccountsActionsService.getTrackingAccountsActions({
          idaccountaction: this.dataClient.id,
        });
        return result;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    resolveUserStatusVariant(status) {
      if (status === 1) return "warning";
      if (status === 2) return "success";
      if (status === 3) return "danger";
      return "primary";
    },
  },
};
</script>
<style scoped lang="scss">
.observation {
  width: 100%;
  height: 50px;
  border: 1px solid rgb(0 0 0 / 54%);
  overflow-y: auto;
  border-radius: 5px;
  padding: 5px;
}
</style>
