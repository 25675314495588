import { title } from "@core/utils/filter";

const AccountsActionsFields = [
  {
    key: "name_client",
    label: "Clients",
    formatter: title,
  },
  {
    key: "name_bank",
    label: "Account",
  },
  {
    key: "balance",
    label: "Balance",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "offer_amount",
    label: "Offer Amount",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "offer",
    label: "% Offer",
    thClass: "text-center",
    tdClass: "pt-2 text-center",
  },
  {
    key: "settlement_fee",
    label: "% Settlement Fee",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "result",
    label: "Result",
    thClass: "text-center",
    tdClass: "pt-2 text-center",
  },
  {
    key: "created_at",
    label: "Created By",
    sortable: true,
  },
  {
    key: "Tracking",
    thClass: "text-center",
  },
  {
    key: "status",
    thClass: "text-center",
  },
];

export default {
  AccountsActionsFields,
};
